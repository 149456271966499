const commonColors = {
  white: '#ffffff',

  black: '#000000',
  black85: '#000000D9',
  black65: '#000000A6',
  black35: '#00000059',
  black10: '#0000001A',

  greyback: '#f3f2f2',

  blogtag: '#FD7272',
  noticetag: '#82589F',
  qandatag: '#FC427B',
  newstag: '#353b48',
  polltag: '#B33771',
  generaltag: '#1B9CFC',
  lessontag: '#FA9D45',

  successColor: '#00b578',
  warningColor: '#ff8f1f',
  dangerColor: '#ff3141',

  btnBlue: '#0084FF',
  linkColor: '#0084FF',

  appColorTeal: '#1FC498',
};

const lightTheme = {
  isDark: false,
  primary: '#0000ff',
  secondary: '#2C2C63',
  text: 'rgba(58,52,51,1)',
  textSecondary: 'rgba(58,52,51,0.7)',
  background: 'rgba(255,255,255,1)',
  backgroundVariant: 'rgba(251,249,249,1)',
  border: 'rgba(58,52,51,0.12)',
  borderLight: 'rgba(58,52,51,0.05)',
  listItemBgColor: '#e6f7ff',
  ...commonColors,
};

const darkTheme: Theme = {
  isDark: true,
  primary: '#0000ff',
  secondary: '#2C2C63',
  text: 'rgba(241,233,231,1)',
  textSecondary: 'rgba(241,233,231,0.6)',
  background: 'rgba(0,0,0,1)',
  backgroundVariant: 'rgba(28,26,26,1)',
  border: 'rgba(241,233,231,0.15)',
  borderLight: 'rgba(241,233,231,0.05)',
  listItemBgColor: '#e6f7ff',
  ...commonColors,
};

const defaultTheme = {
  companyTitle: 'Cleandesk',
  companyLogo: '/logo96whitebg.png?x=10000000',
  // companyLogo: '/logo96tranparent.png?x=10000000',
  isDark: false,
  primary: '#0000ff',
  secondary: '#4F68F7',
  text: 'rgba(58,52,51,1)',
  textSecondary: 'rgba(58,52,51,0.7)',
  background: 'rgba(255,255,255,1)',
  backgroundVariant: 'rgba(251,249,249,1)',
  border: 'rgba(58,52,51,0.12)',
  borderLight: 'rgba(58,52,51,0.05)',
  listItemBgColor: '#e6f7ff',
  ...commonColors,
};

export type Theme = typeof lightTheme;

export const themes = {
  light: lightTheme,
  dark: darkTheme,
  default: defaultTheme,
};

export const AddNewTheme = (orgUiDetails: any, key: string, myCallback) => {
  const newTheme = {
    companyTitle: orgUiDetails.title,
    companyLogo: orgUiDetails.logo,
    isDark: false,
    primary: orgUiDetails.primary_color,
    secondary: '#4F68F7',
    text: 'rgba(58,52,51,1)',
    textSecondary: 'rgba(58,52,51,0.7)',
    background: 'rgba(255,255,255,1)',
    backgroundVariant: 'rgba(251,249,249,1)',
    border: 'rgba(58,52,51,0.12)',
    borderLight: 'rgba(58,52,51,0.05)',
    listItemBgColor: orgUiDetails.primary_color + '40',
  };
  themes[key] = { ...newTheme, ...commonColors };

  myCallback(key);
};
